import React from 'react'
import PropTypes from 'prop-types'
import { Breadcrumbs as HorizonBreadcrumbs } from 'ascential-horizon'
import { removeActiveCrumb } from './removeActiveCrumb'

export const Breadcrumbs = ({
  crumbs,
  crumbLabel,
  location,
  customLeadCrumb,
}) => {
  const trailingCrumbs = removeActiveCrumb(crumbs, location)

  if (customLeadCrumb) {
    const lastCrumb = trailingCrumbs[trailingCrumbs.length - 1]
    const updatedLastCrumb = {
      ...lastCrumb,
      crumbLabel: customLeadCrumb,
    }
    trailingCrumbs[trailingCrumbs.length - 1] = updatedLastCrumb
  }

  return (
    crumbs &&
    crumbs.length > 0 && (
      <HorizonBreadcrumbs crumbs={trailingCrumbs} activeCrumb={crumbLabel} />
    )
  )
}

Breadcrumbs.propTypes = {
  crumbLabel: PropTypes.string,
  crumbs: PropTypes.arrayOf(
    PropTypes.shape({
      crumbLabel: PropTypes.string,
      pathname: PropTypes.string,
    })
  ),
  customLeadCrumb: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
}

Breadcrumbs.defaultProps = {
  crumbLabel: '',
  crumbs: [],
  customLeadCrumb: '',
}

export default Breadcrumbs
