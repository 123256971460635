const removeTrailingSlash = (pathname) =>
  pathname.substr(-1) === '/'
    ? pathname.substr(0, pathname.length - 1)
    : pathname

const isCrumbPathSameAsLocationPath = (crumb, location) =>
  removeTrailingSlash(location.pathname) === removeTrailingSlash(crumb.pathname)

export const removeActiveCrumb = (crumbs, location) => {
  return crumbs.filter(
    (crumb) => isCrumbPathSameAsLocationPath(crumb, location) !== true
  )
}

export default removeActiveCrumb
