import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from 'emotion-theming'
import { Hero as HorizonHero } from 'ascential-horizon'
import { useWindowWidth } from '../../utils/hooks'
import { trackBtnClick } from '../../services/tracking'

const Hero = (hero) => {
  const {
    title,
    subtitle,
    ctaUrl,
    ctaLabel,
    image,
    variant,
    video,
    mobileImage,
    banner,
    headlineImage,
  } = hero
  const { breakpoints } = useTheme()
  const windowWidth = useWindowWidth()
  const overTablet = windowWidth >= parseInt(breakpoints.medium, 10)

  const imageFluid = overTablet
    ? image && image.fluid
    : (mobileImage && mobileImage.fluid) || (image && image.fluid)

  const mediaProps =
    video && overTablet
      ? {
          video: {
            url: video.file.url,
            playing: true,
            loop: true,
            muted: true,
            controls: false,
            zoom: '1',
          },
          image: null,
        }
      : {
          fluid: imageFluid,
        }

  if (banner && banner.image && !banner.image.fluid) {
    banner.image.src = banner.image.file.url
  }

  const heroProps = {
    cta: ctaLabel && {
      label: ctaLabel,
      url: ctaUrl,
      onClick: () => trackBtnClick(ctaLabel, ctaUrl, 'hero.click'),
    },
    subTitle: subtitle,
    title: title || '',
    variant: variant || 'article',
    ...mediaProps,
    banner,
    headlineImage: headlineImage || null,
  }

  return <>{image && image.fluid && <HorizonHero {...heroProps} />}</>
}

Hero.propTypes = {
  hero: PropTypes.oneOfType([PropTypes.object]),
}

Hero.defaultProps = {
  hero: {},
}

export default Hero
